/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        changeHeader();
        upArrow();
        animateTopSection();
        animateOther();
        hoverImage();
        //form();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Top Section animation

  function animateTopSection() {
    TweenLite.to(".top-about-section .top-about-wrap", 1.2, {top:"50%", opacity:"1", ease:Power2.easeOut});
  };

  // Animate other

  function animateOther() {

    var steps_animated = false;
    $(window).scroll(function(){

        if($(this).scrollTop() >= $('.steps-section').position().top - window.innerHeight/3*2 && !steps_animated) {
            steps_animated = true;
            TweenLite.to(".steps-section #step1", 0.4, {delay:0, paddingTop:50, opacity:"1", ease:Power2.easeOut});
            TweenLite.to(".steps-section #step2", 0.4, {delay:0.2, paddingTop:50, opacity:"1", ease:Power2.easeOut});
            TweenLite.to(".steps-section #step3", 0.4, {delay:0.4, paddingTop:50, opacity:"1", ease:Power2.easeOut});
            TweenLite.to(".steps-section #step-gif", 0.4, {delay:0.6, paddingTop:50, opacity:"1", ease:Power2.easeOut});
        }
        else if ($(this).scrollTop() <= $('.steps-section').position().top - window.innerHeight/3*2 && steps_animated) {
            steps_animated = false;
            TweenLite.to(".steps-section #step1", 0.4, {delay:0.6, paddingTop:0, opacity:"0", ease:Power2.easeOut});
            TweenLite.to(".steps-section #step2", 0.4, {delay:0.4, paddingTop:0, opacity:"0", ease:Power2.easeOut});
            TweenLite.to(".steps-section #step3", 0.4, {delay:0.2, paddingTop:0, opacity:"0", ease:Power2.easeOut});
            TweenLite.to(".steps-section #step-gif", 0.4, {paddingTop:0, opacity:"0", ease:Power2.easeOut});
        }
    });

    var illu_animated = false;
    $(window).scroll(function(){

        if($(this).scrollTop() >= $('.illustration-section').position().top - window.innerHeight/3*1.8 && !illu_animated) {
            illu_animated = true;
            TweenLite.to(".illustration img", 1.4, {top:"50%", opacity:"1", ease:Power2.easeOut});
        }
        else if ($(this).scrollTop() <= $('.illustration-section').position().top - window.innerHeight/3*1.8 && illu_animated) {
            illu_animated = false;
            TweenLite.to(".illustration img", 1.4, {top:"50%", opacity:"0", ease:Power2.easeOut});
        }
    });

  };

  // Custom functions

  function changeHeader() {

    $('header .logo').css("top","-80px");
    $('header .up-arrow').css("top","-80px");
    var menu_down = false;
    $(window).scroll(function(){

        if($(this).scrollTop() >= $('.top-about-section').outerHeight( true ) && !menu_down) {
            menu_down = true;
            TweenLite.to("header .logo", 0.4, {top:"0", ease:Power2.easeOut});
            TweenLite.to("header .up-arrow", 0.4, {top:"0", ease:Power2.easeOut});
        }
        else if ($(this).scrollTop() <= $('.top-about-section').outerHeight( true ) && menu_down) {
            menu_down = false;
            TweenLite.to("header .logo", 0.3, {top:"-80", ease:Power2.easeOut});
            TweenLite.to("header .up-arrow", 0.3, {top:"-80", ease:Power2.easeOut});
        }
    });

    $(document).scroll(function(){

      if($(this).scrollTop()>= 30 ){
        $('header .brand').fadeOut();
  	  }
      else if ($(this).scrollTop()<= 30) {
        $('header .brand').fadeIn('fast');
      }

  		if($(this).scrollTop()>=$('.header-color-green').position().top - 30 ){
        // console.log('hej');
        //$('header .brand').css("color","#2eac66");
        //$('header .brand').fadeOut();

        $('header .logo .logo-green').show();
        $('header .logo .logo-white').hide();
        $('header .logo .logo-blue').hide();

        $('header .up-arrow #arrow-white').hide();
        $('header .up-arrow #arrow-green').show();
        $('header .up-arrow #arrow-blue').hide();
  	  }
      if($(this).scrollTop()>=$('.header-color-blue').position().top - 30 ){
        //$('header .brand').css("color","#2d53a1");
        //$('header .brand').fadeOut();

        $('header .logo .logo-green').hide();
        $('header .logo .logo-white').hide();
        $('header .logo .logo-blue').show();

        $('header .up-arrow #arrow-white').hide();
        $('header .up-arrow #arrow-green').hide();
        $('header .up-arrow #arrow-blue').show();
  	  }
      if($(this).scrollTop()>=$('.header-color-white').position().top - 30 ){
        //$('header .brand').css("color","white");
        //$('header .brand').fadeIn();

        $('header .logo .logo-green').hide();
        $('header .logo .logo-white').show();
        $('header .logo .logo-blue').hide();

        $('header .up-arrow #arrow-white').show();
        $('header .up-arrow #arrow-green').hide();
        $('header .up-arrow #arrow-blue').hide();
  	  }
  		else if($(this).scrollTop()<=$('.header-color-green').position().top - 30){
        //$('header .brand').css("color","white");
        //$('header .brand').fadeIn('fast');

        $('header .logo .logo-green').hide();
        //$('header .logo .logo-white').show();
        $('header .logo .logo-blue').hide();

        //$('header .up-arrow #arrow-white').show();
        $('header .up-arrow #arrow-green').hide();
        $('header .up-arrow #arrow-blue').hide();
  	  }
  		else {


  		}

  	});

  };

  // Click Up Arrow

  function upArrow() {
    $('.up-arrow').on('click', function(e) {
      $("html, body").animate({ scrollTop: 0 }, "slow");
	  });
  };

  // Links Hover Image Cursor

  function hoverImage() {

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
     // some code..
    }
    else {

      $('.certified-link a').mousemove(function(event) {

          $(this).parents().find('.data-centers-background').css({"background-image": "url("+$(this).parent().find('img').attr("src")+")"});

          TweenLite.to($(this).parents().find('.data-centers-background'), 0.6, { opacity:1 , ease:Power2.easeOut});

      });

      $('.certified-link a').mouseleave(function(event) {

          TweenLite.to($(this).parents().find('.data-centers-background'), 0.6, { opacity:0 , ease:Power2.easeOut});

      });

    }

  }

  // function form() {
  //
  //   (function($) {
  //     $.QueryString = (function(a) {
  //       if (a == "") return {};
  //       var b = {};
  //       for (var i = 0; i < a.length; ++i)
  //       {
  //         var p=a[i].split('=');
  //         if (p.length != 2) continue;
  //         b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  //       }
  //       return b;
  //     })(window.location.search.substr(1).split('&'))
  //   })(jQuery);
  //   var speedUp = 10;
  //   var speedDown = 30000;
  //   var repeat = $.QueryString['repeat'];
  //   $(function() {
  //     $( "#success-message" ).dialog({
  //       resizable: false,
  //       height:'auto',
  //       minWidth : 300,
  //       modal: true,
  //       width: 'auto',
  //       autoOpen: false,
  //       buttons: {
  //         Ok: function() {
  //           $( this ).dialog( "close" );
  //         }
  //       }
  //     });
  //
  //     if(repeat == 1) {
  //       setTimeout(function() {
  //         scrollDown($('html, body'));
  //       }, 1000)
  //
  //     }
  //   });
  //
  //   function scrollDown(el) {
  //     $.scrollTo(el[0].scrollHeight, speedDown, {
  //       onAfter: function() {
  //         setTimeout(function() { scrollUp(el) }, 1000);
  //       }
  //     })
  //   };
  //
  //   function scrollUp(el) {
  //     $.scrollTo(0, speedUp, {
  //       onAfter: function() {
  //         setTimeout(function() { scrollDown(el) }, 1000);
  //       }
  //     })
  //   };
  //   var busyForm = false;
  //   function SendAjaxForm() {
  //     var form = document.forms['frmOrder'];
  //     if (busyForm) {
  //       alert('In progress. Please wait.')
  //     }
  //     $('.error').removeClass('error');
  //     $('.frmLoader').show();
  //     var options = {
  //       dataType: 'json',
  //       success: function(response) {
  //         $('.frmLoader').hide();
  //         busyForm = false;
  //         if (response.status == 'ok') {
  //           $( "#success-message" ).dialog( "open" );
  //           form.reset();
  //         }
  //         else {
  //           $('.frmLoader').hide();
  //           if (response.status == 'validation_error') {
  //             for (var i = 0; i < response.error.length; i++) {
  //               $('form[name=frmOrder] input[name='+response.error[i]['field']+']').addClass('error');
  //               $('form[name=frmOrder] input[name='+response.error[i]['field']+']').val('');
  //             }
  //           } else {
  //             alert(response.message);
  //           }
  //         }
  //       },
  //       error: function(data) {
  //         $('.frmLoader').hide();
  //         busyForm = false;
  //         alert("Proceed problems. Please try again.");
  //       }
  //     }
  //
  //     $(form).ajaxSubmit(options);
  //
  //     return false;
  //   }
  //
  //
  // }

})(jQuery); // Fully reference jQuery after this point.
